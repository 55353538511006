.navbar {
    .logo {
        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
        font-size: 3vw !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        cursor: pointer;
    }
    
    .underline {
        height: 0.14vw;
        background: linear-gradient(to right, #FFFFFF, #868686 48%, #FFFFFF );
        border-radius: 1vw;
        margin-bottom: 2vw;
        width: 13vw;
        margin-right: auto;
        margin-left: auto;
        margin-top: 0.5vw;
        margin-bottom: 0.5vw;
    }
}
