.navbar {
    &.unsigned {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .menu-container {
            background: linear-gradient(to right, #F06F6F, #933EB1);
            width: fit-content;
            border-radius: 2vw;
            padding: 0.5vw;
            padding-left: 3vw;
            padding-right: 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 12vw;

            .header {
                color: white;
                font-size: 2vw;
                cursor: pointer;
                display: flex;
            }
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 2vw;

            .button {
                width: 8vw;
                height: fit-content;
                text-align: center;
                justify-content: center;
                color: white;
                padding: 1vw;
                border-radius: 2vw;
                font-size: 1vw;
                margin-top: auto;
                margin-bottom: auto;
                background: linear-gradient( to top right, #E93737, #E91DE1);
                cursor: pointer;
                
                &.sales {
                    background: linear-gradient( to right, #bc2cf0, #e7328a);
                }
            }
        }
    }
}
