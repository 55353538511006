.dropdown-container {
    .options {
        background: white;
        width: 14vw;
        height: 2vw;
        border-radius: 0.5vw;
        padding: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border: 1px solid gainsboro;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            border: none;
            color: white;
        }
    }

    .selector {
        width: 14vw;
        height: 2vw;
        border-radius: 0.5vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;

        .placeholder {
            margin-right: auto;
            margin-left: auto;
            color: rgb(139, 139, 139);
        }

        .arrow {
            width: 1.5vw;
            height: 1.5vw;
            transform: rotate(-90deg);

            &.active {
                transform: rotate(90deg);
            }
        }
    }
}