.pop-up {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.active {
        display: block;
    }

    .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.25;
        z-index: 5;
        border: 1px solid black;
    }

    .content {
        z-index: 10;
        position: relative;
        width: fit-content;
        height: fit-content;
    }
}
