.LoginPage {
    .content {
        display: flex;
        flex-direction: row;
        column-gap: 6vw;
        width: 80vw;
        margin-top: 8vh;
        margin-right: auto;
        margin-left: auto;
        justify-content: center;

        .photo-slogan {
            .slogan {
                font-size: 2.5vw;
                text-align: center;
                width: 22vw;
                margin-right: auto;
                margin-left: auto;
                margin-top: 1vw;
            }
            
            img {
                width: 32vw;
                height: 32vw;
                overflow-y: auto;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .register-container {
            background: white;
            border: 1px solid #EFEFEF;
            width: 30vw;
            height: fit-content;
            border-radius: 2vw;
            padding: 2vw;
            display: flex;
            flex-direction: column;

            .header {
                color: black;
                text-align: center;
                font-size: 2vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .sub-header {
                text-align: center;
                font-size: 1.5vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 1vw;
                margin-bottom: 1vw;
                width: 23vw;
                margin-right: auto;
                margin-left: auto;
            }

            input {
                margin-bottom: 2.5vw;
                margin-top: 2vw;
                padding: 1vw;
                border: 1px solid white;
                border-radius: 2vw;
                outline: 1px solid white;
            }

            .submit {
                background: linear-gradient(to right, #F06F6F, #933EB1);
                border-radius: 2vw;
                padding: 1vw;
                display: flex;
                flex-direction: row;
                column-gap: 0.5vw;
                width: fit-content;
                margin-right: auto;
                margin-lefT: auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: white;
            }

            .login-text {
                font-size: 1vw;
                margin-top: 1vw;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
