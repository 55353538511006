.unreleased {
    opacity: 0.9;
    display: flex;
    flex-direction: row;

    img {
        width: 45vw;
        height: 45vw;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title, .date-text  {
            font-size: 2.5vw;
            text-align: center;
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-family: "Inknut Antiqua";
        }

        .date-text {
            font-size: 2vw;
            margin-top: 1vw;
        }

        .date {
            font-size: 1.5vw;
            width: fit-content;
            margin-right: auto;
            margin-left: auto;
            padding: 1vw;
            border-radius: 1vw;
            margin-top: 2vw;

            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            color: white;
        }
    }
}
