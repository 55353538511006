.Home {
    .gray-background {
        background: #F8F9FA;
    }

    .header {
        .content {
            margin-top: 5vw;
            display: flex;
            flex-direction: column;
            gap: 1vw;

            .logo {
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                font-size: 7vw;

                &.title {
                    background-image: linear-gradient(to right, #e60918, #e26cd6);
                    font-size: 5vw;
                    width: 55vw;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            .desc {
                width: 30vw;
                margin-right: auto;
                margin-left: auto;
                font-size: 1vw;
                text-align: center;
            }

            .button-container {
                width: fit-content;
                display: flex;
                flex-direction: row;
                gap: 2vw;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 10vw;

                .button {
                    background: linear-gradient( to top right, #c740f8, #f1499b 70%);
                    width: 6vw;
                    height: fit-content;
                    text-align: center;
                    color: white;
                    padding: 1vw;
                    border-radius: 2vw;
                    font-size: 1vw;
                    cursor: pointer;

                    &.transparent {
                        background: transparent;
                        border: 1px solid gainsboro;
                        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                        font-family: "Inknut Antiqua";
                    }
                }
            }
        }
    }

    .image-overlay {

        .logo {
            font-size: 5vw;
            width: 50vw;
            margin-top: -6vw;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
        }

        img {
            width: 48vw;
            height: 30vw;
            border-radius: 2vw;
            cursor: pointer;

            &.focus {
                z-index: 8;
            }

            &.left-top {
                position: absolute;
                top: 54vw;
                left: 1vw;
            }

            &.left-bottom {
                position: absolute;
                top: 64vw;
                left: 1vw;
            }

            &.right-top {
                position: absolute;
                top: 54vw;
                right: 1vw;
            }

            &.right-bottom {
                position: absolute;
                top: 64vw;
                right: 1vw;
            }

            &.center {
                position: absolute;
                top: 60vw;
                right: 25vw;
            }
        }
    }

    .our-services {
        padding: 4vw;
        margin-top: 42vw;

        .title {
            color: #FF1083;
            font-family: "Expletus Sans";
            font-size: 1.5vw;
            text-shadow: 2px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .split-text {
            display: flex;
            flex-direction: row;

            &-container {
                .title {
                    color: white;
                    width: 45vw;
    
                    .text {
                        font-size: 5vw;
                        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                        text-shadow: none;
                    }
    
                    .text-platform {
                        position: relative;
                        top: -10.5vh;
                        left: 9vw;
                        font-size: 4.5vw;
                        font-family: "Libre Barcode 128 Text";
                        height: fit-content;
                        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                        text-shadow: none;
                    }
                }

                .card-offer {
                    background-color: white;
                    padding: 2vw;
                    width: 30vw;
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 3vh;
                    border-radius: 2vw;

                    svg { 
                        width: 8vw;
                        height: 8vw;
                    }

                    .title {
                        color: black;
                        font-size: 2.5vw;
                        font-family: "Expletus Sans";
                        text-shadow: none;
                    }

                    .description {
                        font-family: "Expletus Sans";
                        color: black;
                        font-size: 1vw;
                        text-shadow: none;
                    }
                }
            }
        }
    }

    .more-questions {
        width: 60vw;
        margin-right: auto;
        margin-left: auto;
        background: linear-gradient( to top right, #c740f8, #f1499b 70%);
        border-radius: 2vw;
        padding: 2vw;
        display: flex;
        flex-direction: row;
        margin-bottom: 10vw;

        .form {
            display: flex;
            flex-direction: column;
            gap: 1vw;

            .title {
                color: white;
                font-size: 3vw;
            }

            .desc {
                width: fit-content;
                font-size: 1.5vw;
                color: white;
            }

            .email-form {
                width: fit-content;
                background: white;
                display: flex;
                flex-direction: row;
                gap: 1vw;
                padding: 1vw;
                border-radius: 0.5vw;

                svg {
                    width: 1.5vw;
                    height: 1.5vw;
                    padding: 0.5vw;
                    padding-left: 0.8vw;
                    padding-right: 0.8vw;
                    border-radius: 1vw;
                    background: linear-gradient( to top right, #F04E4E, #7C0B8E);
                }

                input {
                    width: 23.5vw;
                    outline: none;
                    border: none;
                    font-size: 1.2vw;
                    color: black;

                    &::placeholder {
                        color: black;
                    }
                }

                .button {
                    background: linear-gradient( to top right, #c740f8, #f1499b 70%);
                    width: 6vw;
                    height: fit-content;
                    text-align: center;
                    color: white;
                    padding: 0.6vw;
                    margin-top: auto;
                    margin-bottom: auto;
                    border-radius: 2vw;
                    font-size: 1vw;
                    cursor: pointer;
                }
            }
        }

        img {
            width: 20vw;
            height: 13vw;
            margin-right: auto;
            margin-left: auto;
        }
    }
}
