@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap');

.box-shadow {
    box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.25);
}

.MainPage {
    display: flex;
    flex-direction: row;
}
