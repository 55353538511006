.collapsible {
    border-radius: 1vw;
    padding: 0.2vw;
    display: flex;
    flex-direction: column;

    &.active {
        .arrow {
            transform: rotate(90deg) !important;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .arrow {
            width: 3vw;
            height: 3vw;
            transform: rotate(-90deg);
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .content {
        width: 70vw;
        margin-right: auto;
        margin-left: auto;
    }
}